import Button from '@/components/Button';
import FormTextTip from '@/components/Form/FormTextTip';
import { Tit } from '@/components/Titles';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding-top: 129px;
  .tip {
    padding-bottom: 96px;
    margin-top: 24px;
    ${FormTextTip} {
      font-size: 16px;
    }
  }

  .btn-wrap {
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }
`;

const VillageStep3: FC = ({ ...props }) => {
  const { handleSubmit } = useForm();
  return (
    <StepContainer>
      <form>
        <Tit size="s1-5" color="sky" weight="normal">
          유니세프 어린이체험관 <br />
          신청이 완료되었습니다.
        </Tit>
        <p className="tip">
          <FormTextTip>
            마이페이지와 이메일에서 <strong>체험관 신청 내역을 확인</strong>
            해주세요.
          </FormTextTip>
        </p>
        <div className="btn-wrap">
          <Button size="sm" type="submit">
            확인
          </Button>
        </div>
      </form>
    </StepContainer>
  );
};
export default VillageStep3;
