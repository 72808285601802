import React, { useRef, KeyboardEvent } from 'react';
import {
  default as ReactSelect,
  components,
  Props as ReactSelectProps,
} from 'react-select';
import styled from 'styled-components';
import iconSelectArrow from '@/assets/img/icons/icon_select_arrow_down@2x.png';

export type Option = {
  value: number | string;
  label: string;
};

// 스타일 정의
const CustomListbox = styled.div`
  #react-select-2-listbox {
    padding: 0;
  }
`;

interface MultiSelectProps extends ReactSelectProps<Option> {
  isSelectAll?: boolean;
  width?: string;
  height?: string;
  placeholderText?: string;
}

const MultiSelect = (props: MultiSelectProps) => {
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>('Select all');
  const allOption: Option = { value: '*', label: selectAllLabel.current };

  const filterOptions = (options: Option[], input: string) =>
    options?.filter(({ label }: Option) =>
      label.toLowerCase().includes(input.toLowerCase()),
    );

  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  const filteredOptions = filterOptions(props.options as Option[], '');
  const filteredSelectedOptions = filterOptions(props.value as Option[], '');

  const OptionComponent = (optionProps: any) => (
    <components.Option {...optionProps}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {optionProps.value === '*' &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > 0 ? (
          <input
            key={optionProps.value}
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = true;
            }}
            style={{
              width: 'auto',
              accentColor: '#fff',
              marginRight: '10px',
            }}
          />
        ) : (
          <input
            key={optionProps.value}
            type="checkbox"
            checked={optionProps.isSelected || isAllSelected.current}
            onChange={() => {}}
            style={{
              width: 'auto',
              accentColor: '#fff',
              marginRight: '10px',
            }}
          />
        )}
        <label style={{ marginLeft: '5px' }}>{optionProps.label}</label>
      </div>
    </components.Option>
  );

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === ' ' || e.key === 'Enter') e.preventDefault();
  };

  const handleChange = (selected: Option[]) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange?.(
        [
          ...((props.value as Option[]) ?? []),
          ...(props.options as Option[]).filter(
            ({ label }: Option) =>
              label.toLowerCase().includes('') &&
              ((props.value as Option[]) ?? []).filter(
                (opt: Option) => opt.label === label,
              ).length === 0,
          ),
        ].sort(comparator),
      );
    if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange?.(selected);
    return props.onChange?.([
      ...((props.value as Option[]) ?? []).filter(
        ({ label }: Option) => !label.toLowerCase().includes(''),
      ),
    ]);
  };

  //   // 선택된 항목 갯수만 표시하는 커스텀 ValueContainer
  // const ValueContainer = ({ children, ...props }: any) => {
  //   const { getValue } = props;
  //   const values = getValue();
  //   const count = values.length;

  //   return (
  //     <components.ValueContainer {...props}>
  //       {count > 0 ? `${count}개 선택됨` : children}
  //     </components.ValueContainer>
  //   );
  // };

  const customStyles = {
    multiValue: (base: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#eaeaea',
      margin: '1px',
      flexBasis: 'auto',
    }),
    multiValueLabel: (def: any) => ({
      ...def,
      fontSize: '12px',
      backgroundColor: '#eaeaea',
    }),
    multiValueRemove: (def: any) => ({
      ...def,
      backgroundColor: '#eaeaea',
    }),
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: '65px',
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '0px 0px',
      paddingLeft: '2px',
    }),
    option: (styles: any, { isSelected, isFocused }: any) => ({
      ...styles,
      backgroundColor: isSelected || isFocused ? '#DEEBFF' : null,
      color: isSelected ? null : null,
      padding: '1px 12px',
      '&:hover': {
        backgroundColor: '#1967d2',
      },
    }),
    menu: (def: any) => ({
      ...def,
      zIndex: 9999,
      marginTop: '2px',
      marginBottom: 0,
      borderRadius: '0',
    }),
    control: (base: any, state: any) => ({
      ...base,
      width: props.width || 'auto',
      height: props.height || 'auto',
      display: 'flex',
      overflow: 'hidden',
      borderColor: '#e5e6e8',
      borderRadius: '2px',
      boxShadow: state.isFocused ? '0 0 0 2px #000' : base.boxShadow,
      background: `url(${iconSelectArrow}) center no-repeat`,
      backgroundPositionY: '50%',
      backgroundPositionX: '97%',
      backgroundSize: '24px',
      '&:hover': {
        borderColor: '#e5e6e8',
      },
      '&:focus-visible': {
        borderColor: '#e5e6e8',
        outLine: 'none',
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#2d2926',
      marginLeft: '10px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0 16px',
      color: '#858688',
      '& svg': {
        display: 'none',
      },
    }),
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length) {
        selectAllLabel.current = `모두 선택`;
      } else {
        selectAllLabel.current = `${filteredSelectedOptions?.length}개 / ${filteredOptions.length} 선택`;
      }
    } else selectAllLabel.current = '모두 선택';

    allOption.label = selectAllLabel.current;

    return (
      <CustomListbox>
        <ReactSelect
          {...props}
          inputValue=""
          onKeyDown={onKeyDown}
          options={[allOption, ...(props.options as Option[])]}
          onChange={handleChange}
          components={{
            Option: OptionComponent,
            ...props.components,
            IndicatorSeparator: () => null,
            // ValueContainer: ValueContainer,
          }}
          filterOption={() => true}
          styles={customStyles}
          menuPlacement={props.menuPlacement ?? 'auto'}
          isMulti
          closeMenuOnSelect={false}
          tabSelectsValue={false}
          backspaceRemovesValue={false}
          hideSelectedOptions={false}
          blurInputOnSelect={false}
          placeholder={props.placeholderText || '선택...'}
          isSearchable={false}
        />
      </CustomListbox>
    );
  }

  return (
    <CustomListbox>
      <ReactSelect
        {...props}
        inputValue=""
        filterOption={() => true}
        components={{
          ...props.components,
          IndicatorSeparator: () => null,
        }}
        menuPlacement={props.menuPlacement ?? 'auto'}
        onKeyDown={onKeyDown}
        tabSelectsValue={false}
        hideSelectedOptions
        backspaceRemovesValue={false}
        blurInputOnSelect
        placeholder={props.placeholderText || '선택...'}
        isSearchable={false}
        styles={{
          ...customStyles,
          multiValueLabel: (base: any) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#eaeaea',
            '@media (max-width: 768px)': {
              fontSize: '10px',
            },
          }),
        }}
      />
    </CustomListbox>
  );
};

export default MultiSelect;
