/* eslint-disable no-nested-ternary */
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Autoplay, Navigation, Scrollbar } from 'swiper';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Image from '../Image';
import LinkSafe from '../LinkSafe';
import SwiperBasic from '../Swiper/SwiperBasic';
import { Tit } from '../Titles';

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

interface CampaignProps {
  campaigns: CampaignData[];
  img?: boolean;
  overflowHidden?: boolean;
  auto?: boolean;
}

export interface CampaignData {
  id: string | number;
  pcImage: ImgHTMLAttributes<any>['src'];
  mobileImage: ImgHTMLAttributes<any>['src'];
  title?: string;
  link?: string;
  linkTargetBlank?: boolean;
  csrName?: string;
  dec?: string;
  date?: string;
}

const CardLayout = styled.div`
  ${Tit} {
    & del {
      text-decoration: none;
      color: #1cabe2;
    }
    small {
      font-size: 16px;
      display: block;
      margin-top: 16px;
      font-weight: 400;
    }
  }
`;
const CardImage = styled.div`
  overflow: hidden;
  position: relative;

  .img-box {
    width: 100%;
    padding-top: 44.12%;
  }

  .card-img {
    max-width: 100%;
    width: 100%;
  }
`;
const CardTitle = styled.p`
  box-sizing: border-box;
  background: #fff;
  font-weight: bold;
  padding: 24px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 0 0 40px 0;
  margin: 0;
`;

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  .swiper-container {
    padding-bottom: 112px;
  }

  .swiper-slide {
    overflow: hidden;
    width: 476px;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoint(1160)} {
      width: 41.03vw;
      margin-right: 3.45vw;
    }
    ${breakpoint(`mobile`)} {
      width: 280px;
      margin-right: 16px;
    }
  }
`;

const VillageCard: FC<CampaignProps> = ({ campaigns, img, hash, ...props }) => {
  const stopYoutubeVideos = () => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (iframe.src.includes('youtube.com')) {
        iframe.contentWindow.postMessage(
          JSON.stringify({ event: 'command', func: 'pauseVideo' }),
          '*',
        );
      }
    });
  };

  return (
    <SwiperCustom {...props}>
      <Swiper
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
        autoplay={props.autoplay}
        onSlideChange={stopYoutubeVideos}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 1,
          },
        }}
      >
        {campaigns.map((campaign) => (
          <SwiperSlide key={campaign.id}>
            {campaign.link !== undefined ? (
              props.mainEvent ? (
                <LinkSafe
                  to={campaign.link || `/`}
                  target="_blank"
                  onClick={() => {
                    if (hash) {
                      window.location.hash = hash;
                    }
                  }}
                >
                  <CardLayout className="CardLayout">
                    <CardImage className="img-wrap">
                      {img ? (
                        <Image
                          className="card-img"
                          pcSrc={campaign.pcImage}
                          mobileSrc={campaign.mobileImage}
                        />
                      ) : (
                        <>
                          {campaign.videoSrc ? (
                            <YoutubeSingle
                              className="youtube-container"
                              videoId={campaign.videoSrc}
                            />
                          ) : (
                            <div
                              className="img-box"
                              css={`
                                background-image: url(${campaign.pcImage});
                              `}
                            />
                          )}
                        </>
                      )}
                    </CardImage>
                    <div className="dec-wrap">
                      {campaign.title && <CardTitle>{campaign.title}</CardTitle>}
                      {campaign.csrName && (
                        <CardTitle>{campaign.csrName}</CardTitle>
                      )}
                      {campaign.dec && (
                        <Tit
                          size="s3"
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: campaign.dec }}
                        />
                      )}
                    </div>
                  </CardLayout>
                </LinkSafe>
              ) : (
                <LinkSafe
                  to={campaign.link || `/`}
                  target={campaign.linkTargetBlank ? `_blank` : ``}
                  onClick={() => {
                    if (hash) {
                      window.location.hash = hash;
                    }
                  }}
                >
                  <CardLayout className="CardLayout">
                    <CardImage className="img-wrap">
                      {img ? (
                        <Image
                          className="card-img"
                          pcSrc={campaign.pcImage}
                          mobileSrc={campaign.mobileImage}
                        />
                      ) : (
                        <>
                          {campaign.videoSrc ? (
                            <YoutubeSingle
                              className="youtube-container"
                              videoId={campaign.videoSrc}
                            />
                          ) : (
                            <div
                              className="img-box"
                              css={`
                                background-image: url(${campaign.pcImage});
                              `}
                            />
                          )}
                        </>
                      )}
                    </CardImage>
                    <div className="dec-wrap">
                      {campaign.title && <CardTitle>{campaign.title}</CardTitle>}
                      {campaign.csrName && (
                        <CardTitle>{campaign.csrName}</CardTitle>
                      )}
                      {campaign.dec && (
                        <Tit
                          size="s3"
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: campaign.dec }}
                        />
                      )}
                    </div>
                  </CardLayout>
                </LinkSafe>
              )
            ) : (
              <CardLayout className="CardLayout">
                <CardImage className="img-wrap">
                  {img ? (
                    <Image
                      className="card-img"
                      pcSrc={campaign.pcImage}
                      mobileSrc={campaign.mobileImage}
                    />
                  ) : (
                    <>
                      {campaign.videoSrc ? (
                        <YoutubeSingle
                          className="youtube-container"
                          videoId={campaign.videoSrc}
                        />
                      ) : (
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${campaign.pcImage});
                          `}
                        />
                      )}
                    </>
                  )}
                </CardImage>
                <div className="dec-wrap">
                  {campaign.title && <CardTitle>{campaign.title}</CardTitle>}
                  {campaign.csrName && <CardTitle>{campaign.csrName}</CardTitle>}
                  {campaign.dec && (
                    <Tit
                      size="s3"
                      className="dec"
                      dangerouslySetInnerHTML={{ __html: campaign.dec }}
                    />
                  )}
                </div>
              </CardLayout>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperCustom>
  );
};
export default VillageCard;
